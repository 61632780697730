import { useState, useEffect } from "react"
import Cookies from "js-cookie"
import costPerMile from "../constants/cost_per_mile.json"
import { replaceValue } from "../util/string"
import geo from "../services/geoLocation"

export const useGeoDataEffect = (geoPostDescription?: string) => {
  const [geoDescription, setGeoDescription] = useState("")
  const [geoSessionValue, setGeoSessionValue] = useState(
    Cookies.get("geoLocation"),
  )

  useEffect(() => {
    const fetchGeoData = async () => {
      let geoData = geoSessionValue ? JSON.parse(geoSessionValue) : null
      if (!geoData) {
        try {
          geoData = await geo.getGeoLocation()
          if (geoData && geoData.country_code2 === "US") {
            Cookies.set("geoLocation", geoData, { expires: 7 })
            setGeoSessionValue(geoData)
          }
        } catch (error) {
          console.error("Error fetching geolocation", error)
          return
        }
      }

      if (geoData && geoData.country_code2 === "US") {
        const geoCity = geoData.city || ""
        const matchingLocation = costPerMile.find(
          (location) => location.city.toLowerCase() === geoCity.toLowerCase(),
        )
        const cost_per_mile = matchingLocation
          ? matchingLocation.cost_per_mile
          : costPerMile.find(
              (location) => location.city.toLowerCase() === "overall",
            )?.cost_per_mile
        if (geoPostDescription) {
          const updatedDescription = replaceValue(geoPostDescription, {
            location: geoCity,
            cost_per_mile,
          })
          setGeoDescription(updatedDescription)
        }
      }
    }

    fetchGeoData()
  }, [geoPostDescription, geoSessionValue])

  return { geoDescription, geoSessionValue }
}
