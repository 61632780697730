import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { useRouter } from "next/router"
import { AiFillCheckCircle } from "react-icons/ai"
import { BlokImage } from ".."
import ShippingForm from "../../components/form/ShippingForm"
import PageNav, { NavigationType } from "./PageNav"
import hero_bg from "../../assets/images/hero_bg.webp"
import TrustpilotGoogleReviews from "../../components/TrustpilotGoogleReviews"
import Breadcrumb, { BreadcrumbItem } from "../../components/breadcrumbs"
import { readImage } from "../../app/utils/read-image"
import { useGeoDataEffect } from "../../hooks/useGeoData"

export interface IPageHeader extends SbBlokData {
  heading: string
  details: string
  image: BlokImage
  smImage: BlokImage
  buttonLabel: string
  hasSelectCategory?: boolean
  hasPickupInput?: boolean
  hasDeliveryInput?: boolean
  selectCategoryPlaceholder?: string
  buttonLink?: string
  categoryOptions: { label: string; value: string }[]
  unsubscribeText?: string
  caption1?: string
  caption2?: string
  navItems: NavigationType["navItems"]
  categories: NavigationType["categories"]
  isNavHidden?: boolean
  ctaRoute?: string
  items?: BreadcrumbItem[]
  hasGeoCustomization?: boolean
  geoPostDescription?: string
}

export default function PageHeader3(props: { blok: IPageHeader }) {
  const { query } = useRouter()
  const {
    heading,
    details,
    image,
    smImage,
    buttonLabel,
    hasSelectCategory,
    hasPickupInput,
    hasDeliveryInput,
    categoryOptions,
    selectCategoryPlaceholder = "What are you shipping",
    buttonLink,
    unsubscribeText,
    caption1 = "Fast and easy",
    caption2 = "Only pay when you book",
    navItems,
    categories,
    isNavHidden,
    ctaRoute,
    items,
    hasGeoCustomization = false,
    geoPostDescription = "Hire top-rated transporters in {location}",
  } = props.blok

  const param = (query?.kw as string) || ""
  const { geoDescription } = useGeoDataEffect(geoPostDescription)

  return (
    <>
      <PageNav
        noBlok
        route={ctaRoute || buttonLink}
        buttonLabel={buttonLabel}
        navigationData={{ navItems, categories, isNavHidden }}
      />
      <section
        className="relative w-full pt-24 p-0 m-0 -mb-[2px] bg-cover overflow-x-hidden"
        style={{ backgroundImage: `url(${hero_bg.src})` }}
      >
        <div className="relative lg:absolute top-0 right-0 w-full h-40 lg:w-[40%] xl:w-[55%] lg:h-full overflow-hidden lg:overflow-visible">
          <Image
            className="scale-110 hidden md:block lg:scale-100 object-cover object-[-18px_36%] lg:object-bottom overflow-x-visible"
            src={readImage(image)}
            alt={image.description || image.alt}
            fill
            priority={true}
          />
          <Image
            className="md:hidden object-cover object-top overflow-x-visible mx-auto h-40 rounded-md"
            src={readImage(smImage)}
            alt={smImage.description || image.alt}
            width={350}
            height={150}
            priority={true}
          />
          <TrustpilotGoogleReviews className="hidden absolute lg:flex bottom-48 right-0 header-padding py-0 pl-0 z-50" />
        </div>
        <header
          {...storyblokEditable(props.blok)}
          className="relative header-padding xl:min-h-[900px] 2xl:pb-8 lg:pt-32 pt-0 w-full flex flex-col lg:flex-row lg:gap-14 xl:gap-28 overflow-hidden"
        >
          <article className="relative w-full lg:w-2/5 z-10">
            {items && <Breadcrumb items={items} />}
            <h1 className="text-xl md:text-5xl text-blue7 font-semibold font-montserrat mb-2 sm:mb-8">
              {param ? param : heading}
            </h1>
            <h2
              id="PageHeader3-details"
              className="mb-2 sm:mb-8 text-base sm:text-xl text-blue5 font-normal"
            >
              {details}{" "}
              {hasGeoCustomization && geoDescription
                ? geoDescription
                : "Match with trusted drivers in just minutes!"}
            </h2>
            <ShippingForm
              section="pageHeader"
              hasSelectCategory={hasSelectCategory}
              hasPickupInput={hasPickupInput}
              hasDeliveryInput={hasDeliveryInput}
              categoryOptions={categoryOptions}
              selectCategoryPlaceholder={selectCategoryPlaceholder}
              buttonLink={buttonLink}
              buttonLabel={buttonLabel}
              unsubscribeText={unsubscribeText}
            />
            <aside className="my-4 flex flex-wrap justify-center items-center">
              <div className="flex items-center">
                <AiFillCheckCircle className="h-4 w-4 mr-2 fill-green5" />
                <p className="m-0 mr-4">{caption1}</p>
              </div>
              <div className="flex items-center">
                <AiFillCheckCircle className="h-4 w-4 mr-2 fill-green5" />
                <p className="m-0 mr-4">{caption2}</p>
              </div>
            </aside>
          </article>

          <TrustpilotGoogleReviews className="lg:hidden" />
        </header>
      </section>
    </>
  )
}
