import { FC } from "react"
import { marked } from "marked"
import Image from "next/image"
import { Anchor } from "../../app/components/anchor"
import classNames from "classnames"
import { readImage } from "../../app/utils/read-image"
import { BlokImage } from "../../storyblok"
import PageNav, { NavigationType } from "../../storyblok/sections/PageNav"
import { PremiumHeroForm } from "./hero-form"
import { PremiumHeroListingForm } from "./hero-listing-form"
import StarRating from "../../components/StarRating"
import Badge from "../../assets/images/all-driver-reviews/moved-badge.svg"
import { useGeoDataEffect } from "../../hooks/useGeoData"
import { useReviewsContext } from "../../context/ReviewsContext"
import PremiumSocialProof from "./social-proof"

export interface IPremiumHero {
  blok: {
    tagline: string
    title: string
    description: string
    buttonLink?: string
    buttonLabel?: string
    bgImage?: BlokImage
    bgSmImage?: BlokImage
    navItems?: NavigationType["navItems"]
    categories?: NavigationType["categories"]
    isNavHidden?: boolean
    ctaRoute?: string
    hasNavigation?: boolean
    showBadge?: boolean
    geoPostDescription?: string
    geoTitle?: string
    hasGeoCustomization?: boolean
    hasSwapCustomization?: boolean
    showReviewCard?: boolean
    showSocialProof?: boolean
    stats?: {
      title?: string
      text?: string
    }[]
    hasListingForm?: boolean
    animalType?: string
    bgImagePlacement?: string
    bgOpacity?: string
  }
}
const PremiumHero: FC<IPremiumHero> = (props) => {
  const {
    tagline,
    title,
    description,
    buttonLabel,
    buttonLink,
    bgSmImage,
    bgImage,
    ctaRoute,
    navItems = [],
    categories = [],
    isNavHidden,
    hasNavigation,
    showBadge = false,
    hasGeoCustomization = false,
    hasSwapCustomization = false,
    geoPostDescription = "Hire top-rated transporters in {location}.",
    showReviewCard = false,
    showSocialProof = false,
    geoTitle = "",
    stats,
    hasListingForm = false,
    animalType = "dog",
    bgImagePlacement = "center",
    bgOpacity,
  } = props.blok

  const { geoDescription, geoSessionValue } = useGeoDataEffect(
    geoPostDescription,
  )
  const geoLocationTitle = useGeoDataEffect(geoTitle)

  const { blokReviews } = useReviewsContext()
  const height = showReviewCard ? 395 : 385

  return (
    <>
      {hasNavigation && (
        <PageNav
          noBlok
          route={ctaRoute || buttonLink}
          buttonLabel={buttonLabel}
          navigationData={{ navItems, categories, isNavHidden }}
          position=""
        />
      )}
      <section
        id="premium-hero"
        className={classNames("relative md:h-[685px]")}
      >
        <div className={`absolute h-[${height}px] inset-0 z-1 md:h-full`}>
          {bgImage && (
            <Image
              className="hidden sm:block"
              src={readImage(bgImage)}
              alt={bgImage.alt || "hero image"}
              layout="fill"
              objectFit="cover"
              objectPosition={bgImagePlacement}
              priority
            />
          )}
          {bgSmImage &&
            (showReviewCard ? (
              <Image
                className="sm:hidden"
                src={readImage(bgSmImage ?? bgImage)}
                alt={bgSmImage.alt || "hero image"}
                width={540}
                height={height}
                objectPosition="center"
              />
            ) : (
              <Image
                className="sm:hidden"
                src={readImage(bgSmImage ?? bgImage)}
                alt={bgSmImage.alt || "hero image"}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
              />
            ))}
          {showBadge && (
            <Image
              className="w-[96px] h-[96px] sm:w-[100px] sm:h-[100px] md:w-[120px] md:h-[120px] absolute bottom-4 right-4 sm:bottom-10 md:bottom-[160px] md:right-10 z-10"
              src={Badge}
              alt={"100k pets transported"}
              width={120}
              height={120}
            />
          )}
        </div>

        <div
          className={`absolute h-[${height}px] inset-0 bg-black bg-opacity-55 md:h-full`}
        ></div>

        <div
          className={`h-[${height}px] md:h-full relative max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto px-6 lg:px-8 py-8`}
        >
          <div className="text-white font-merriweather max-w-3xl text-left md:mt-10 lg:mt-20 mb-0">
            {showReviewCard && (
              <div className="flex font-work_sans mb-6" id="reviews">
                <section className="w-[128px] h-[60px] p-2 rounded-lg shadow-md bg-white mr-2">
                  <Image
                    className="h-[22px] w-auto"
                    src="/icons/google.svg"
                    alt="google logo"
                    width={100}
                    height={20}
                  />

                  <div className="w-full flex flex-row justify-start items-center text-sm">
                    <StarRating value={5} className="w-20" />

                    <Anchor
                      href="https://www.google.com/maps/place/CitizenShipper/@32.2908037,-95.3054722,17z/data=!4m7!3m6!1s0x0:0xaa0dc85bb2e33171!8m2!3d32.2908037!4d-95.3054722!9m1!1b1?hl=en"
                      target="_blank"
                      rel="noreferrer noopener"
                      passHref
                    >
                      {blokReviews?.googleRatings}
                    </Anchor>
                  </div>
                </section>
                <section className="w-[128px] h-[60px] p-2 rounded-lg shadow-md bg-white">
                  <Image
                    className="h-[22px] w-auto"
                    src="/icons/trust-pilot.svg"
                    alt="trust pilot logo"
                    width={100}
                    height={20}
                  />

                  <div className="w-full flex flex-row  justify-start items-center text-sm">
                    <StarRating value={5} className="w-20" />

                    <Anchor
                      href="https://www.trustpilot.com/review/citizenshipper.com?stars=5#:~:text=Reply%20from%20CitizenShipper"
                      target="_blank"
                      rel="noreferrer noopener"
                      passHref
                    >
                      {blokReviews?.trustpilotRatings}
                    </Anchor>
                  </div>
                </section>
              </div>
            )}
            <p
              className="text-md font-light italic md:mt-6"
              dangerouslySetInnerHTML={{
                __html: marked(tagline),
              }}
            />

            <h1
              className="text-[26px] leading-[50px] sm:text-[42px] sm:leading-[72px] md:text-[56px] font-bold lg:text-[56px] mt-1 md:mt-4 mb-6"
              dangerouslySetInnerHTML={{
                __html: marked(
                  geoLocationTitle.geoDescription
                    ? geoLocationTitle.geoDescription
                    : title,
                ),
              }}
            />
            <p
              id="PremiumHero-description"
              className="text-lg max-w-lg font-inter"
              dangerouslySetInnerHTML={{
                __html: marked(
                  `${description} ${
                    hasGeoCustomization ? geoDescription : ""
                  } `,
                ),
              }}
            />
          </div>
        </div>
        <div
          className={classNames(
            "relative lg:absolute lg:-bottom-24 max-w-7xl mx-auto md:flex justify-center bg-white rounded-1 px-6 md:px-5 lg:px-8 py-11 right-0 left-0",
            hasListingForm ? "md:h-[300px]" : "md:h-[240px]",
          )}
        >
          <div>
            {hasListingForm ? (
              <PremiumHeroListingForm
                buttonLabel={buttonLabel}
                buttonLink={buttonLink}
                animalType={animalType}
              />
            ) : (
              <>
                <PremiumHeroForm
                  buttonLabel={buttonLabel}
                  buttonLink={buttonLink}
                  hasSwapCustomization={hasSwapCustomization}
                  geoSessionValue={geoSessionValue}
                />
                {showSocialProof && <PremiumSocialProof stats={stats} />}
              </>
            )}
          </div>
          <div></div>
        </div>
      </section>
      <section className="lg:mb-10" />
    </>
  )
}
export default PremiumHero
